import React from "react";
import Styles from "./DevByDev.module.scss";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Arrow from "../../../images/LP/enterprise/arrow.png";

const Checks = (props) => {
  return (
    <>
      <div className={Styles.DevByDevWrap}>
        <section>
          <div className={Styles.DevByDevHeader}>
            <h2>
              It’s purpose-built for&nbsp;
              <br />
              developers by developers.
            </h2>
          </div>
          <div className={Styles.DevByDev}>
            {props.data.map((data, index) => {
              return (
                <div className={Styles.DevByDevCard} key={index}>
                  <h5>{data.header}</h5>
                  <p>{data.desc}</p>
                  <a href={data.link}>
                    {data.anchor}
                    <img src={data.arrow} />
                  </a>
                </div>
              );
            })}
          </div>
        </section>

        <div className={Styles.DevByDevAccordion}>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <span>Plugs into your existing HR systems seamlessly</span>
                  <img src={Arrow} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  Our API integrates with the HRMS and ATS tools you already
                  use. You don't have to dedicate a special time of effort to
                  get started with us.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <span>Our API commitment and public documentation</span>
                  <img src={Arrow} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  Our flexible and easy-to-use API documentation helps you get
                  started instantly. Build a tailored solution that fits your
                  organization’s needs.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <span>
                    Embrace transparency and control with Blockchain & AI
                  </span>
                  <img src={Arrow} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  Get the combined benefits of an AI and Blockchain to boost
                  accuracy, speed, and reliability with every background check
                  you make.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  <span>Increase productivity with workflow automation</span>
                  <img src={Arrow} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  Wave goodbye to repetitive manual tasks and cumbersome
                  paperwork. Save time and focus on what matters by automating
                  your verifications easily.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  <span>Comes equipped with Webhook endpoints</span>
                  <img src={Arrow} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  You don’t have to call our API to know the status of the
                  checks. SpringVerify automatically notifies and keeps you
                  up-to-date at every step of the process.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  <span>
                    Build a fool-proof verification system with a pre-production
                    environment
                  </span>
                  <img src={Arrow} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  Conduct tests in our pre-production environment with mock
                  data. This can save time and effort compared to experimenting
                  with real-time data.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Checks;
