import React from "react";
import Styles from "./APIUnque.module.scss";

const Checks = (props) => {
  return (
    <>
      <div className={Styles.APIUniqueWrap}>
        <section>
          <div className={Styles.APIUniqueHeader}>
            <h2>
              What makes <br />
              SpringVerify’s API unique?
            </h2>
          </div>
          <div className={Styles.APIUnique}>
            {props.data.map((data, index) => {
              return (
                <div className={Styles.APIUniqueCard} key={index}>
                  <img src={data.path} alt="SpringVerify" />
                  <p>{data.header}</p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export default Checks;
