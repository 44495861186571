import React from "react";
import Banner from "../../components/Landers/Banner";
import Footer from "../../components/Landers/Footer";
import GetStarted from "../../components/Landers/GetStarted";
import Header from "../../components/Landers/Header";
import styles from "../../components/Landers/landers.module.scss";
import SEO from "../../components/Landers/SEO";
import VerificationExp from "../../components/Landers/API/VerificationExp";
import APIUnique from "../../components/Landers/APIUnque";
import DevByDev from "../../components/Landers/DevByDev";
import DataInfo from "../../components/Data";

export default () => (
  <div className={`${styles.apiIntegrations} ${styles.m0}`}>
    <SEO
      title="API for Background Checks made easy and seamless| SpringVerify India"
      desc="Integrate SpringVerify India's API to your pre-built tools like ATS, HRIS in just hours not days for the most seamless onboarding."
      url="https://in.springverify.com/api-integrations/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/SV-IN+API+SM+image.jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={"Robust, reliable, and \neasy-to-integrate API you \ncan count on"}
        p="Our developer-friendly API is built around REST and the responses are returned in JSON. It practices standard HTTP response codes, authentication, and verbs helping you get started in no time."
        a="API Doc"
        img="https://assets-sv-in.gumlet.io/LP/api-integrations/banner.svg"
      />
    </div>
    <APIUnique data={DataInfo.APIUnique} />
    <DevByDev data={DataInfo.DevByDev} />
    <div className="HGreySection">
      <VerificationExp />
    </div>
    <GetStarted
      img="api-integrations/getStarted.svg"
      h3="Scale your hiring with SpringVerify"
      a="Get Started"
    />
    <Footer />
  </div>
);
